import { IPayPalCreateLink } from '@negotium/models';
import commonRequestHeaders from '../common/requestHeaders';
import { AxiosError } from 'axios';

export const createPaymentPayPal = async ({
	lang,
	cartShopId,
	currency,
	type,
}: {
	cartShopId: string;
	currency: string;
	type: string;
	lang: 'es' | 'en';
}): Promise<IPayPalCreateLink> => {
	try {
		const headers = await commonRequestHeaders();

		const promise = await fetch(
			`${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/payPal/createPayment`,
			{
				method: 'POST',
				credentials: 'include',
				headers: headers,
				body: JSON.stringify({ lang, cartShopId, currency, type }),
			}
		);

		if (!promise.ok) {
			if (promise.status === 401) {
				throw new Error(promise.statusText);
			}
		}
		const { data, result, message } = await promise.json();
		if (result === 2) {
			throw new Error(message);
		}

		return {
			status: 200,
			data: data,
			message: message,
		};
	} catch (error) {
		throw new Error((error as AxiosError).message);
	}
};

export const createTransferPayPal = async (
	transferId: string,
	lang: 'es' | 'en'
): Promise<IPayPalCreateLink> => {
	try {
		const headers = await commonRequestHeaders();
		const apiUrl = `${process.env['NEXT_PUBLIC_API_URL_BACK_OFFICE']}/payPal/createPaymentTransfer`;

		const response = await fetch(apiUrl, {
			method: 'POST',
			credentials: 'include',
			headers,
			body: JSON.stringify({ lang, transferId }),
		});

		let responseData: IPayPalCreateLink | null = null;
		try {
			responseData = await response.json();
		} catch (jsonError) {
			throw new Error(`Invalid JSON response from API (${response.status})`);
		}

		if (!response.ok) {
			const errorMessage =
				responseData?.message ||
				`Request failed with status ${response.status}`;

			if (response.status === 401) {
				return { status: 401, data: null, message: 'Unauthorized' };
			}

			throw new Error(errorMessage);
		}

		if (
			!responseData ||
			typeof responseData !== 'object' ||
			!('data' in responseData)
		) {
			throw new Error('Invalid API response structure');
		}

		if (responseData.result === 2) {
			throw new Error(responseData.message || 'Unexpected API error');
		}

		return {
			status: 200,
			data: responseData.data,
			message: responseData.message || 'Success',
		};
	} catch (error: unknown) {
		const errorMessage =
			error instanceof Error ? error.message : 'Unknown error occurred';

		throw new Error(errorMessage);
	}
};
